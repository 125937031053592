$pink: #ff6f96;
$black: #000;
$yellow: #ffbe00;
$gold: #ffbf00;
$transparent-gold: rgba($gold, 0.4);
$blue-light: #aad6ff;
$blue-alice: #e8f2ff;
$blue-pressed: #5eb0fc;
$blue-solitude: #eef1f7;
$green-bright: #43cb16;
$green-fresh: #8ad861;
$color-secondary-grey: #edf1f7;
$color-tertiary-grey: #b6bbcc;
$base-grey: #0A0B09;
$grey: #888888;
$grey-generic: #757575;
$grey-eb: #ebebeb;
$grey-nobel: #b5b5b5;
$grey-light: #f7f7f7;
$grey-tabby: #616161;
$grey-alabaster: #f9f9f9;
$grey-very-light: #dddddd;
$grey-light-silver: #d8d8d8;
$silver: #c4c4c4;
$white: #ffffff;
$white-smoke: #f5f5f5;
$max-content-width: 1440px;

//new colors
$blue-bright: #005dff;
$blue-medium-bright: #0057d8;
$blue-light: #aad6ff;
$blue-dark: #141b4a;
$blue-navy-dark: #151e5a;
$brand-green: #8ad861;
$brand-yellow: #ffbf00;
$brand-pink: #ff6f96;
$white: #fff;
$black: #000;
$green: #17850f;
$contextual-error: #e23232;
$contextual-error-light: #f9d6d6;
$erie-black: #252525;
$success-200: #e1efdf;
$success-300: #c9e5c7;
$base-gray: #424242;

// state colors
$error: #e60000;
$success: $green;
$warning: #f1c40f;
$disabled: #b6bbcc;

$badge-green: #09a14d;
$badge-orange: #ff8700;
$badge-red: #eb0505;

$tranistion-design-color-replace-blue-bright: #0e3849;

$font-sen: var(--sen, Sen-Regular);

$font-quicksand: var(--quicksand, Quicksand-Regular);

$spacing-2xs: 0.2rem;
$spacing-xs: 0.4rem;
$spacing-s: 0.8rem;
$spacing-m: 1.6rem;
$spacing-l: 2.4rem;
$spacing-xl: 3.2rem;
$spacing-2xl: 4rem;
$spacing-3xl: 4.8rem;
$spacing-4xl: 6.4rem;
$spacing-5xl: 8.8rem;
$spacing-6xl: 12.8rem;
$spacing-7xl: 25.6rem;

$spacing-content-block-top: 5.6rem;
$spacing-content-block-bottom-s: 7.2rem;
$spacing-content-block-bottom: 8rem;

$xs-col-gap: 1.2rem;
$sm-col-gap: 1.6rem;
$md-col-gap: 1.6rem;
$lg-col-gap: 1.6rem;

$header-height-mobile: 64px;
$simple-header-height-mobile: 110px;
$header-height-desktop: 142px;
$simple-header-height-desktop: 110px;

$max-content-width: 1440px;

$modal-header-font-size: 2.4rem;
